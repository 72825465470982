import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../../Layout'
import Badge from '../../atoms/Badge'
import SectionResources from '../../organisms/SectionResources'
import Graphic from '../../pages/technology/Marketplaces/Graphic'
import Cta from './Cta'
import Overview from './Overview'
import Details from './Details'
import styles from './index.module.css'
import ButtonActions from '../../molecules/ButtonActions'

export default function PageTemplateTechnology(props: PageProps) {
  const {
    feature,
    badge,
    title,
    description,
    image,
    resources,
    overview,
    details,
    graphicMarketplace,
    actions
  } = (props.data as any).allTechnologyJson.edges[0].node
  return (
    <Layout
      title={title}
      description={description}
      uri={props.uri}
      location={props.location}
      heroCenter
      heroLabel={
        badge ? (
          <>
            <Badge className={styles.badge} label={badge} /> {feature}
          </>
        ) : (
          feature
        )
      }
      heroContent={
        graphicMarketplace ? <Graphic content={graphicMarketplace} /> : null
      }
    >
      {overview && (
        <Overview
          overview={overview}
          image={image && image.childImageSharp && image.childImageSharp.fluid}
        />
      )}
      {details && <Details details={details} />}

      {actions && (
        <ButtonActions className={styles.actions} actions={actions} />
      )}

      {resources && (
        <SectionResources
          title={`${feature} Resources`}
          resources={resources}
        />
      )}
      <Cta />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageTechnologyBySlug($slug: String!) {
    allTechnologyJson(filter: { fields: { slug: { eq: $slug } } }) {
      edges {
        node {
          title
          description
          feature
          badge
          image {
            childImageSharp {
              fluid(maxWidth: 506) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          graphicMarketplace {
            title
            text
          }
          overview {
            text
            list
          }
          details {
            title
            description
            items {
              title
              description
              links {
                title
                url
              }
            }
          }
          resources {
            prominent {
              title
              description
              url
            }
            links {
              title
              url
            }
          }
          actions {
            title
            url
          }
        }
      }
    }
  }
`
